import React, { useMemo, useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { Modal } from '../../../../components/modals/Modal';
import { produceShouldUpdate } from '../../../../services/fieldUtils';

const t = (key, isAwaitingItem = false) =>
  I18n.t(
    `public_chemical_library.${
      isAwaitingItem ? 'awaiting_items.edit' : 'products.new_edit'
    }.${key}`,
  );
const saveModalT = (key, isAwaitingItem = false) =>
  I18n.t(
    `public_chemical_library.${
      isAwaitingItem ? 'awaiting_items.edit' : 'products.new_edit'
    }.modals.save_modal.${key}`,
  );

const defineSubmitTypes = isAwaitingItem => {
  const awaitingItemSubmitTypes = [
    { value: 'save', data: saveModalT('submit_types.save', isAwaitingItem) },
    {
      value: 'merge',
      data: saveModalT('submit_types.merge', isAwaitingItem),
    },
    {
      value: 'approve_awaiting_item',
      data: saveModalT('submit_types.approve_awaiting_item', isAwaitingItem),
    },
  ];
  const productSubmitTypes = [
    { value: 'save', data: saveModalT('submit_types.save') },
    { value: 'merge', data: saveModalT('submit_types.merge') },
  ];
  return isAwaitingItem ? awaitingItemSubmitTypes : productSubmitTypes;
};

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id, submitType, isAwaitingItem },
  } = props;

  const saveModalRef = useRef(null);

  const submitTypes = useMemo(() => defineSubmitTypes(isAwaitingItem), [isAwaitingItem]);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            modelName={I18n.t(
              `activerecord.models.public_chemical_library/${
                isAwaitingItem ? 'awaiting_item' : 'product'
              }`,
            )}
            hidden={!isDefined(id)}
            onSubmit={async () => {
              const { ok, data } = await API.publicChemicalLibrary.products.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy', isAwaitingItem));
                window.location.href = `/public_chemical_library/${
                  isAwaitingItem ? 'awaiting_items' : 'products'
                }`;
              } else {
                showBackendErrorMessage(t('flash.error.destroy', isAwaitingItem), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton
            type="submit"
            disabled={isSubmitting}
            onClick={async () => {
              if (isDefined(id)) {
                saveModalRef.current.open();
              } else {
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }
            }}
          >
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={saveModalT('title', isAwaitingItem)} ref={saveModalRef}>
        <ModalSection>
          <FastField
            name="submitType"
            label={saveModalT('submit_type', isAwaitingItem)}
            component={SelectorField}
            displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
            options={submitTypes}
            onChange={async (event, onChange) => {
              await onChange(event);
              if (event.value === 'approve_awaiting_item') {
                await setFieldValue('is_reviewed', true);
              }
            }}
          />
          {submitType === 'merge' && (
            <FastField
              name="existingProductId"
              required
              label={saveModalT('existing_product_id', isAwaitingItem)}
              api={API.publicChemicalLibrary.selectors.products.index}
              apiParams={{ without_id: id }}
              selectionApi={API.publicChemicalLibrary.selectors.products.show}
              optionRenderer={htmlOptionRenderer('text')}
              displayMode={fieldDisplayModes.FULL_WIDTH_FORM_ROW}
              component={AsyncSelectorField}
              shouldUpdate={produceShouldUpdate([], [], ['submitType'])}
            />
          )}
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <BrandButton
                onClick={async () => {
                  saveModalRef.current.hide();
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {saveModalT('save', isAwaitingItem)}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
