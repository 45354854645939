import styled from 'styled-components';
import { fontSize } from '../../../../assets/styles/typography';
import { colors } from '../../../../assets/styles/colors';
import { breakpoints, breakpointsForMaxWidth } from '../../../../assets/styles/grid';
import * as ghsCodePropertyIcons from '../../../../../../assets/images/svg/chemical_definitions/chemical_properties';

export const GhsPictogramLabel = styled.label.attrs({ htmlFor: props => props.inputId })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  @media only screen and (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    text-align: right;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

export const ghsCodePropertyIconsStyle = {
  height: 40,
  width: 40,
};

export const GHS_CODE_PROPERTY_NAME_ICON_MAP = {
  explosive: ghsCodePropertyIcons.explosive,
  flammable: ghsCodePropertyIcons.flammable,
  oxidiser: ghsCodePropertyIcons.oxidiser,
  compressed_gas: ghsCodePropertyIcons.compressedGas,
  corrosive: ghsCodePropertyIcons.corrosive,
  acute_toxic: ghsCodePropertyIcons.acuteToxic,
  irritant: ghsCodePropertyIcons.irritant,
  health_hazardous: ghsCodePropertyIcons.healthHazardous,
  environmentally_hazardous: ghsCodePropertyIcons.environmentallyHazardous,
};
