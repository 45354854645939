import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { NumericField } from '../../../components/fields/NumericField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { Footer } from './Footer';
import { RelatedUmsList } from './RelatedUmsList';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';

const t = (key, params = {}) => I18n.t(`administration.ums.new_edit.${key}`, params);

export const Form = props => {
  const {
    values: { id, parent_unit_id, maxPositionValue },
    enums: { um_type },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.um',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.um'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          {parent_unit_id && (
            <FastField
              disabled
              name="parent_unit_id"
              api={API.selectors.ums.index}
              selectionApi={API.selectors.ums.show}
              optionRenderer={htmlOptionRenderer('label')}
              headerRenderer={defaultOptionRenderer('header')}
              component={AsyncSelectorField}
            />
          )}
          <FastField name="description" required component={TextField} />
          <FastField name="um_code" required component={TextField} />
          {parent_unit_id && (
            <FastField name="parent_conversion_value" required component={NumericField} />
          )}
          <FastField
            name="position"
            roundPrecision={0}
            component={NumericField}
            warning={t('position_warning', { max_position_value: maxPositionValue })}
          />
          <FastField
            name="um_type"
            disabled={parent_unit_id}
            component={SelectorField}
            options={mapToSelectorOptions(um_type, 'header', 'value')}
            warning={!parent_unit_id && t('um_type_warning')}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      {id && !parent_unit_id && <RelatedUmsList {...props} />}
    </form>
  );
};
