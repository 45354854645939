import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../../assets/styles/typography';
import { colors } from '../../../../assets/styles/colors';
import { breakpoints, breakpointsForMaxWidth } from '../../../../assets/styles/grid';

const Diamond = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 30px;
  transform: rotate(-45deg);
  border: 1px solid black;
`;

const DiamondElement = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  color: black;
  border: 2px solid black;
  font-weight: bold;
`;

const HealthSquare = styled(DiamondElement)`
  background-color: #2f80ed;
  top: 0;
  left: 0;
  border-left: 1px solid black;
  border-top: 1px solid black;
`;

const FlammabilitySquare = styled(DiamondElement)`
  background-color: #df3600;
  top: 0;
  right: 0;
  border-top: 1px solid black;
  border-right: 1px solid black;
`;

const InstabilitySquare = styled(DiamondElement)`
  background-color: #f9db4c;
  bottom: 0;
  right: 0;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
`;

const SpecialSquare = styled(DiamondElement)`
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  font-size: 10px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
`;

const DiamondLabel = styled.label`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  @media only screen and (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    text-align: right;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

const DiamondRow = styled.div`
  display: flex;
  align-items: center;
`;

const DiamondCodeSpan = styled.span`
  font-size: ${fontSize.textMd};
  color: ${colors.grayDark};
  line-height: 36px;
`;

const SquareLabel = styled.span`
  transform: rotate(45deg);
  display: flex;
  align-items: center;
`;

const styleSpecialSymbol = symbol => {
  if (symbol === 'W') {
    return <s>{symbol}</s>;
  }
  return symbol;
};

export const NfpaDiamond = ({
  healthHazard,
  flammabilityHazard,
  instabilityHazard,
  specialHazard,
  nfpaDiamondCode,
}) => (
  <div className="w-100 row mx-0 py-4 px-0 mb-2">
    <DiamondLabel className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex align-items-center">
      {I18n.t('activerecord.attributes.chemical_compound.nfpa_diamond')}
    </DiamondLabel>
    <div className="col-12 col-sm-8 col-md-8 col-lg-4 d-flex">
      <DiamondRow>
        <Diamond>
          <HealthSquare>
            <SquareLabel>{healthHazard}</SquareLabel>
          </HealthSquare>
          <FlammabilitySquare>
            <SquareLabel>{flammabilityHazard}</SquareLabel>
          </FlammabilitySquare>
          <InstabilitySquare>
            <SquareLabel>{instabilityHazard}</SquareLabel>
          </InstabilitySquare>
          <SpecialSquare>
            <SquareLabel>{styleSpecialSymbol(specialHazard)}</SquareLabel>
          </SpecialSquare>
        </Diamond>
        <DiamondCodeSpan>{nfpaDiamondCode}</DiamondCodeSpan>
      </DiamondRow>
    </div>
  </div>
);
