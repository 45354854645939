import React from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate, produceShouldUpdate } from '../../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { defaultHandleSubmit } from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { NumericAndUnitFields } from '../../../../components/fields/compositeFields/NumericAndUnitFields';
import { useRemainingValues } from './useRemainingValues';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.split.${key}`);

export const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    modalRef,
    values: {
      radInventoryId,
      radInventoryItemId,
      target_rad_inventory_id,
      auxiliary_rad_permit_id,
      showMoveInventoryItemSection,
      item_activity_unit_conversion_value,
      item_activity_unit_id,
    },
  } = props;

  useRemainingValues(props);

  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            labelI18nKeyPrefix: 'activerecord.attributes.rad_inventory_item_split',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_inventory_item_split'),
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          }}
        >
          <FastField name="splitted_date_time" required component={DateTimeField} />
          <FastField
            name="remainingActivity"
            disabled
            component={NumericAndUnitFields}
            unitName="item_activity_unit_id"
            label={t('additional_fields.remaining_activity')}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingQuantity"
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            label={t('additional_fields.remaining_quantity')}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="activity"
            required
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            unitProps={{
              required: true,
              disabled: false,
              onChange: e => {
                const newParentConversionValue = e.data.parent_conversion_value || 1;
                const itemUnitConversionValue = item_activity_unit_conversion_value || 1;
                setFieldValue(
                  'unitConversionValuesRatio',
                  newParentConversionValue / itemUnitConversionValue,
                );
                setFieldValue('activity_unit_id', e.value);
              },
            }}
            unitApiParams={{ um_type: ['radioactivity'], um_family: item_activity_unit_id }}
          />
          <FastField
            name="quantity"
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="showMoveInventoryItemSection"
            label={t('additional_fields.show_move_inventory_item_section')}
            component={CheckBoxField}
          />
          {showMoveInventoryItemSection && (
            <>
              <FastField
                name="target_rad_inventory_id"
                label={t('additional_fields.target_rad_inventory_id')}
                required
                api={API.selectors.radInventories.index}
                apiParams={{ without_id: radInventoryId }}
                selectionApi={API.selectors.radInventories.show}
                onChange={async (event, onChange) => {
                  await setFieldValue('auxiliary_rad_permit_id', event?.data?.rad_permit_id);
                  await onChange(event);
                }}
                optionRenderer={htmlOptionRenderer('text')}
                component={AsyncSelectorField}
              />
              <FastField
                name="target_rad_license_item_assignment_id"
                label={t('additional_fields.target_rad_license_item_assignment_id')}
                disabled={!(target_rad_inventory_id && auxiliary_rad_permit_id)}
                required
                api={API.selectors.radLicenseItemAssignments.index}
                apiParams={{
                  rad_permit_id: auxiliary_rad_permit_id,
                  with_rad_inventory_item_id_compatibility: radInventoryItemId,
                }}
                shouldUpdate={produceShouldUpdate(
                  [],
                  [],
                  ['radInventoryItemId', 'auxiliary_rad_permit_id'],
                )}
                hint={t('additional_fields.target_rad_license_item_assignment_id_hint')}
                selectionApi={API.selectors.radLicenseItemAssignments.show}
                optionRenderer={htmlOptionRenderer('text')}
                component={AsyncSelectorField}
              />
            </>
          )}
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={() => modalRef.current.hide()} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {showMoveInventoryItemSection ? t('split_and_move') : t('split')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
