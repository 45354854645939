import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../components/form/FormSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { setFormValuesBasedOnPchl, setRecordType } from './setFormValuesHelper';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';

const t = key => I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.${key}`);

export const PchlProductBrowser = props => {
  const { setShowMainForm, setShowPchlProductBrowser, setFieldValue, resetForm } = props;

  return (
    <FormSection title={t('sections.search_pchl_products')}>
      <div style={{ maxWidth: '500px' }}>
        <FastField
          name="pchl_product_id"
          api={API.selectors.pchlProducts.index}
          apiParams={{ include_product_attributes: true }}
          selectionApi={API.selectors.pchlProducts.show}
          optionRenderer={htmlOptionRenderer('text')}
          displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
          component={AsyncSelectorField}
          onChange={async (event, onChange) => {
            await onChange(event);
            await setFormValuesBasedOnPchl(event.data, setFieldValue);
            await setRecordType('public', setFieldValue);
            setFieldValue('submitType', 'create_based_on_pchl_product');
            setShowMainForm(true);
          }}
        />
      </div>
      <div className="d-flex align-items-center mt-3">
        <span className="mr-3">{t('hints.create_new_pchl_product')}</span>
        <DefaultLink
          onClick={async () => {
            await resetForm();
            // The "name" value is cleared manually purposefully because the
            // resetForm method does not trigger the field change
            await setFieldValue('name', '');
            setShowMainForm(true);
            setShowPchlProductBrowser(false);
          }}
        >
          {t('links.create_new_pchl_product')}
        </DefaultLink>
      </div>
    </FormSection>
  );
};
