import React, { useEffect, useMemo, useState } from 'react';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { PchlProductBrowser } from './PchlProductBrowser';
import { MainForm } from './MainForm';
import {
  PubChemLink,
  SdsHeaderLink,
} from '../../../components/other/chemicalSafety/FormHeaderLinks';
import { API } from '../../../services/api';

const t = key => I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    resetForm,
    currentUser,
    values: { id, pchl_product_id, pub_chem_url, sds_attachments, chemwatch_no },
  } = props;

  const [showPchlProductBrowser, setShowPchlProductBrowser] = useState(false);
  const [showMainForm, setShowMainForm] = useState(true);

  useEffect(() => {
    if (isDefined(id)) {
      setShowPchlProductBrowser(false);
      setShowMainForm(true);
    }
  }, [id]);

  const connectedToPchl = useMemo(() => isDefined(pchl_product_id), [pchl_product_id]);

  const sdsAttachmentUrl = useMemo(() => {
    const { url } = sds_attachments?.[0] || {};
    return url;
  }, [sds_attachments]);

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        additionalActions={[
          <SdsHeaderLink
            id={id}
            sdsAttachmentUrl={sdsAttachmentUrl}
            chemwatchNo={chemwatch_no}
            generateSdsApi={API.chemicalSafety.chemicalDefinitions.generateSds}
            userEmail={currentUser.email}
            translationNamespace="chemical_safety.chemical_libraries.chemical_definitions"
          />,
          <PubChemLink
            id={id}
            pubChemUrl={pub_chem_url}
            translationNamespace="chemical_safety.chemical_libraries.chemical_definitions"
          />,
        ]}
      />
      {showPchlProductBrowser && (
        <PchlProductBrowser
          setShowMainForm={setShowMainForm}
          setShowPchlProductBrowser={setShowPchlProductBrowser}
          setFieldValue={setFieldValue}
          resetForm={resetForm}
        />
      )}
      {showMainForm && (
        <MainForm
          {...props}
          connectedToPchl={connectedToPchl}
          setShowMainForm={setShowMainForm}
          setShowPchlProductBrowser={setShowPchlProductBrowser}
        />
      )}
    </form>
  );
};
