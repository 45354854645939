import React, { useCallback } from 'react';
import { FastField } from 'formik';
import get from 'lodash/get';
import { FormHeader } from '../../../components/form/FormHeader';
import { fieldValue, isDefined } from '../../../services/utils';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { TextField } from '../../../components/fields/TextField';
import { FormSection } from '../../../components/form/FormSection';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { Footer } from './Footer';
import { WithdrawalsList } from './WithdrawalsList';
import { NumericAndUnitFields } from '../../../components/fields/compositeFields/NumericAndUnitFields';
import { useRemainingActivity } from './useRemainingActivity';
import { RadInventoryItemSplitsList } from './RadInventoryItemSplitsList';
import { WasteCollections } from './WasteCollections';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.${key}`);
const modelT = key => I18n.t(`activerecord.attributes.rad_inventory_item.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    resetForm,
    values: {
      id,
      identifier,
      rad_permit_id,
      disposed,
      waste_chemical_items_count,
      withdrawals_count,
      radInventoryId,
      prepareInitialValues,
    },
  } = props;

  useRemainingActivity(props);

  const resetInventoryItem = useCallback(async () => {
    const { ok, data } = await API.radiationSafety.radInventoryItems(radInventoryId).show(id);
    if (ok) resetForm({ values: prepareInitialValues(data.data.attributes) });
  }, [id]);

  const showWasteCollections = waste_chemical_items_count > 0 || disposed;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        investigable={
          isDefined(id) && {
            id,
            identifier,
            type: 'RadInventoryItem',
          }
        }
        auditsLink={
          isDefined(id)
            ? `/radiation_safety/rad_inventories/${radInventoryId}/rad_inventory_items/${id}/audits`
            : ''
        }
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.rad_inventory_item',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.rad_inventory_item'),
          disabled: disposed,
        }}
      >
        {showWasteCollections && <WasteCollections {...props} />}
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="alternate_identifier" component={TextField} />
          <FastField
            name="rad_permit_id"
            required
            disabled
            api={API.selectors.radPermits.index}
            selectionApi={API.selectors.radPermits.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_inventory_item_status_id"
            required
            api={API.selectors.radInventoryItemStatuses.index}
            apiParams={{ open: true }}
            selectionApi={API.selectors.radInventoryItemStatuses.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_license_item_assignment_id"
            required
            api={API.selectors.radLicenseItemAssignments.index}
            apiParams={{ rad_permit_id }}
            selectionApi={API.selectors.radLicenseItemAssignments.show}
            optionRenderer={htmlOptionRenderer('text')}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              await setFieldValue(
                'half_life_days',
                fieldValue(get(newSelection, 'data.half_life_days')),
              );
              await setFieldValue(
                'rad_item_type_id',
                fieldValue(get(newSelection, 'data.rad_item_type_id')),
              );
              await setFieldValue(
                'quantity_unit_id',
                fieldValue(get(newSelection, 'data.quantity_unit_id')),
              );
              await setFieldValue(
                'activity_unit_id',
                fieldValue(get(newSelection, 'data.activity_unit_id')),
              );
              await setFieldValue(
                'quantity_unit_code',
                fieldValue(get(newSelection, 'data.quantity_unit_code')),
              );
              await setFieldValue(
                'activity_unit_code',
                fieldValue(get(newSelection, 'data.activity_unit_code')),
              );
              await setFieldValue('rad_form_id', get(newSelection, 'data.rad_form_id'));
            }}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_compound_id"
            api={API.selectors.radCompounds.index}
            selectionApi={API.selectors.radCompounds.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_form_id"
            disabled
            api={API.selectors.radForms.index}
            selectionApi={API.selectors.radForms.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="rad_item_type_id"
            label={t('additional_fields.rad_item_type_id')}
            disabled
            api={API.selectors.radItemTypes.index}
            selectionApi={API.selectors.radItemTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="half_life_days" required disabled component={TextField} />
          <FastField
            name="started_at"
            required
            disabled={withdrawals_count > 0}
            component={DateTimeField}
            onChange={async (value, onChange) => {
              await onChange(value);
              await setFieldValue('remainingActivityDecayStartAt', value);
            }}
            hint={t('hints.started_at')}
          />
          <FastField
            name="initial_activity"
            required
            disabled={withdrawals_count > 0}
            component={NumericAndUnitFields}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('remainingActivityBase', event.target.value);
            }}
            unitName="activity_unit_id"
            unitProps={{ required: false, disabled: true, onChange: undefined }}
            unitApiParams={{ um_type: ['radioactivity'] }}
            hint={t('hints.initial_activity')}
          />
          <FastField
            name="initial_quantity"
            required
            disabled={withdrawals_count > 0}
            component={NumericAndUnitFields}
            onChange={async (event, onChange) => {
              await onChange(event);
              await setFieldValue('quantity_after_last_change', event.target.value);
            }}
            unitName="quantity_unit_id"
            unitProps={{ required: false, disabled: true, onChange: undefined }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="remainingActivity"
            label={modelT('remaining_activity')}
            disabled
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingQuantity"
            label={modelT('remaining_quantity')}
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            unitApiParams={{ um_type: ['mass'] }}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <WithdrawalsList {...props} resetInventoryItem={resetInventoryItem} />
      <RadInventoryItemSplitsList {...props} resetInventoryItem={resetInventoryItem} />
    </form>
  );
};
