import { isDefined } from '../../../services/utils';

export const setFormValuesBasedOnPchl = (productAttrs, setFieldValue) => {
  Object.entries(productAttrs.product_attributes).forEach(([key, value]) => {
    setFieldValue(key, value);
  });
  setFieldValue(
    'pchlManufacturers',
    productAttrs?.manufacturers?.map(({ plain_text }) => plain_text).join(', '),
  );
  setFieldValue('pchlStorageGroup', productAttrs?.storage_group?.plain_text);
  setFieldValue(
    'pchlHhopTypes',
    productAttrs?.hhop_types?.map(({ plain_text }) => plain_text).join(', '),
  );
  setFieldValue(
    'pchlGhsCodes',
    productAttrs?.ghs_codes?.map(({ plain_text }) => plain_text).join(', '),
  );
  setFieldValue('ghsCodesProperties', productAttrs?.product_attributes?.ghs_codes_properties);
};

export const setRecordType = (recordTypeValue, setFieldValue) => {
  const translatedRecordType = I18n.t(
    `activerecord.attributes.chemical_definition.record_types.${recordTypeValue}`,
  );
  setFieldValue('record_type', translatedRecordType);
};

export const setGhsValuesFromFirstCompound = (
  setFieldValue,
  compoundIds = [],
  currentValues,
) => compoundValues => {
  const {
    ghs_code_ids: currentGhsCodesIds = [],
    ghsCodesProperties: currentGhsCodesProperties = [],
  } = currentValues;

  if (
    compoundIds.length > 0 ||
    currentGhsCodesIds.length > 0 ||
    currentGhsCodesProperties.length > 0
  ) {
    return;
  }

  const {
    ghs_code_ids: compoundGhsCodeIds = [],
    ghs_codes_properties: compoundGhsCodesProperties = [],
  } = compoundValues;

  setFieldValue('ghs_code_ids', compoundGhsCodeIds);
  setFieldValue('ghsCodesProperties', compoundGhsCodesProperties);
  compoundGhsCodesProperties.forEach(propertyName => {
    setFieldValue(propertyName, true);
  });
};

export const setNfpaValuesFromFirstCompound = (
  setFieldValue,
  compoundIds = [],
  currentValues,
) => compoundValues => {
  const {
    nfpa_health_hazard: currentHealthHazard,
    nfpa_flammability_hazard: currentFlammabilityHazard,
    nfpa_instability_hazard: currentInstabilityHazard,
    nfpa_special_hazard: currentSpecialHazard,
  } = currentValues;

  if (
    compoundIds.length > 0 ||
    isDefined(currentHealthHazard) ||
    isDefined(currentFlammabilityHazard) ||
    isDefined(currentInstabilityHazard) ||
    isDefined(currentSpecialHazard)
  ) {
    return;
  }

  const {
    nfpa_health_hazard: compoundHealthHazard,
    nfpa_flammability_hazard: compoundFlammabilityHazard,
    nfpa_instability_hazard: compoundInstabilityHazard,
    nfpa_special_hazard: compoundSpecialHazard,
  } = compoundValues;

  setFieldValue('nfpa_health_hazard', compoundHealthHazard);
  setFieldValue('nfpa_flammability_hazard', compoundFlammabilityHazard);
  setFieldValue('nfpa_instability_hazard', compoundInstabilityHazard);
  setFieldValue('nfpa_special_hazard', compoundSpecialHazard);
};
