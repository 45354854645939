import React from 'react';
import { FastField, withFormik } from 'formik';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import yup from '../../../../components/form/CustomYupValidator';

const t = key =>
  I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.add_waste_collection.${key}`);

const Form = props => {
  const {
    hide,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { rad_inventory_item_id },
  } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix: 'activerecord.attributes.rad_inventory_item',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_inventory_items_waste_chemical_item'),
          }}
        >
          <FastField
            name="waste_chemical_item_id"
            required
            api={API.selectors.wasteChemicalItems.index}
            selectionApi={API.selectors.wasteChemicalItems.show}
            apiParams={{ without_rad_inventory_item_id: rad_inventory_item_id }}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};

const validationSchema = yup.object().shape({
  waste_chemical_item_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

export const AddWasteCollectionModalContent = withFormik({
  mapPropsToValues: props => ({
    rad_inventory_item_id: props.values.id,
    rad_inventory_id: props.values.radInventoryId,
    waste_chemical_item_id: '',
  }),
  handleSubmit: async (values, formikProps) => {
    const { hide, listRef } = formikProps.props;
    const { rad_inventory_id } = values;
    const api = API.radiationSafety.radInventoryItems(rad_inventory_id).wasteCollections;
    const { ok, data } = await api(values.rad_inventory_item_id).create({
      rad_inventory_items_waste_chemical_item: values,
    });
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  validate: produceDefaultValidation(validationSchema),
})(Form);
