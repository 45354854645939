import { useEffect } from 'react';

const setNfpaDiamondCode = (nfpaDiamondLabels, setFieldValue) => {
  const {
    nfpa_health_hazard,
    nfpa_flammability_hazard,
    nfpa_instability_hazard,
    nfpa_special_hazard,
  } = nfpaDiamondLabels;
  let diamondCode = `${nfpa_health_hazard}-${nfpa_flammability_hazard}-${nfpa_instability_hazard}`;
  diamondCode = nfpa_special_hazard !== '' ? `${diamondCode}-${nfpa_special_hazard}` : diamondCode;
  setFieldValue('nfpaDiamondCode', diamondCode);
};

export const useNfpaDiamondAttributes = props => {
  const SPECIAL_HAZARD_LABELS_MAP = {
    0: 'W',
    1: 'RAD',
    2: 'OX',
    3: 'COR',
    4: 'ALK',
    5: 'ACID',
  };
  const {
    values: {
      nfpa_health_hazard,
      nfpa_flammability_hazard,
      nfpa_instability_hazard,
      nfpa_special_hazard,
    },
    enums: {
      nfpaHealthHazardOptions,
      nfpaFlammabilityHazardOptions,
      nfpaInstabilityHazardOptions,
      nfpaSpecialHazardOptions,
    },
    setFieldValue,
  } = props;

  const findHazardLabel = (hazardValue, hazardOptions) => {
    const hazardIndex = hazardOptions.findIndex(({ value }) => value === hazardValue);
    if (hazardOptions === nfpaSpecialHazardOptions) {
      return hazardIndex === -1 ? '' : SPECIAL_HAZARD_LABELS_MAP[hazardIndex];
    }
    return hazardIndex === -1 ? 0 : hazardIndex;
  };

  useEffect(() => {
    (() => {
      const healthHazardLabel = findHazardLabel(nfpa_health_hazard, nfpaHealthHazardOptions);
      const flammabilityHazardLabel = findHazardLabel(
        nfpa_flammability_hazard,
        nfpaFlammabilityHazardOptions,
      );
      const instabilityHazardLabel = findHazardLabel(
        nfpa_instability_hazard,
        nfpaInstabilityHazardOptions,
      );
      const specialHazardLabel = findHazardLabel(nfpa_special_hazard, nfpaSpecialHazardOptions);

      const newNfpaDiamondLabels = {
        nfpa_health_hazard: healthHazardLabel,
        nfpa_flammability_hazard: flammabilityHazardLabel,
        nfpa_instability_hazard: instabilityHazardLabel,
        nfpa_special_hazard: specialHazardLabel,
      };

      setFieldValue('nfpaDiamondLabels', newNfpaDiamondLabels);
      setNfpaDiamondCode(newNfpaDiamondLabels, setFieldValue);
    })();
  }, [nfpa_health_hazard, nfpa_flammability_hazard, nfpa_instability_hazard, nfpa_special_hazard]);
};
