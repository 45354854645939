import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';

const t = key => I18n.t(`inventory.permits.lab_permits.new_edit.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    showConfirmationModal,
    setShowConfirmationModal,
    values: { id },
  } = props;
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const customFieldsDataWarningModalRef = useRef(null);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.permits_fdny')}
            onSubmit={async () => {
              const { ok, data } = await API.permits.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.remove'));
                window.location.href = `/inventory/permits/lab_permits`;
              } else {
                showBackendErrorMessage(t('flash.error.remove'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton
            onClick={async () => {
              if (showConfirmationModal) {
                customFieldsDataWarningModalRef.current.open();
              } else {
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }
            }}
          >
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal
        title={t('modals.custom_fields_data_warning.title')}
        ref={customFieldsDataWarningModalRef}
      >
        <ConfirmationModalContent
          modalRef={customFieldsDataWarningModalRef}
          hint={t('modals.custom_fields_data_warning.warning')}
          confirm={t('modals.custom_fields_data_warning.confirm')}
          onConfirm={async () => {
            setShowConfirmationModal(false);
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
    </>
  );
};
