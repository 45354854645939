import React from 'react';
import { isDefined, showErrorMessage, showSuccessMessage } from '../../../services/utils';
import { DefaultLink } from '../../links/DefaultLink';

const t = (key, translationNamespace, params = {}) =>
  I18n.t(`${translationNamespace}.new_edit.${key}`, params);

export const SdsHeaderLink = ({
  id,
  sdsAttachmentUrl,
  chemwatchNo,
  generateSdsApi,
  userEmail,
  translationNamespace,
}) => {
  if (!isDefined(id) || (!isDefined(sdsAttachmentUrl) && !isDefined(chemwatchNo))) return null;

  if (isDefined(sdsAttachmentUrl)) {
    return (
      <div className="mr-3">
        <DefaultLink href={sdsAttachmentUrl} target="_blank">
          {t('links.download_sds', translationNamespace)}
        </DefaultLink>
      </div>
    );
  }

  return (
    <div className="mr-3">
      <DefaultLink
        onClick={async () => {
          const { ok } = await generateSdsApi(id);
          if (ok) {
            showSuccessMessage(
              t('flash.success.generate_sds', translationNamespace, { email: userEmail }),
            );
          } else {
            showErrorMessage(t('flash.error.generate_sds', translationNamespace));
          }
        }}
      >
        {t('links.download_sds', translationNamespace)}
      </DefaultLink>
    </div>
  );
};

export const PubChemLink = ({ id, pubChemUrl, translationNamespace }) => {
  if (isDefined(id) && isDefined(pubChemUrl)) {
    return (
      <DefaultLink href={pubChemUrl} target="_blank">
        {t('links.show_in_pub_chem', translationNamespace)}
      </DefaultLink>
    );
  }
  return null;
};
