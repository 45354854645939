import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateAllBreadcrumbs,
  updateBreadcrumbs,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = (key, isAwaitingItem = false) =>
  I18n.t(
    `public_chemical_library.${
      isAwaitingItem ? 'awaiting_items.edit' : 'products.new_edit'
    }.${key}`,
  );
const productBreadcrumbsT = key => I18n.t(`breadcrumbs.public_chemical_library.products.${key}`);

const ProductFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const product = ProductFunctions.prepareValuesToSubmit({ ...values });
    const submitAction = ProductFunctions.chooseSubmitAction(values);
    submitAction(product, formikProps, values);
  },
  chooseSubmitAction: values => {
    const { id, submitType } = values;
    if (submitType === 'merge') {
      return ProductFunctions.merge;
    }
    return ProductFunctions[isDefined(id) ? 'update' : 'create'];
  },
  create: async (product, formikProps, values) => {
    const { afterSave } = values;
    product.is_reviewed = true;

    const { ok, data } = await API.publicChemicalLibrary.products.create({
      product,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/public_chemical_library/products/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(t('title.edit'));
      formikProps.resetForm({
        values: ProductFunctions.prepareInitialValues(data.data),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (product, formikProps, values) => {
    const { afterSave, submitType, isAwaitingItem } = values;
    product.is_reviewed = submitType === 'approve_awaiting_item' ? true : product.is_reviewed;

    const { ok, data } = await API.publicChemicalLibrary.products.update(product.id, {
      product,
    });
    if (ok) {
      if (submitType === 'approve_awaiting_item') {
        window.history.pushState(
          '',
          '',
          `/public_chemical_library/products/${data.data.id}/edit${window.location.search}`,
        );
        const productBreadcrumbs = [
          { href: '/public_chemical_library/products', text: productBreadcrumbsT('index.title') },
          { href: window.location.href, text: productBreadcrumbsT('edit.title') },
        ];
        updateAllBreadcrumbs(productBreadcrumbs);
      }
      formikProps.resetForm({
        values: ProductFunctions.prepareInitialValues(data.data),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save', isAwaitingItem));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', isAwaitingItem), data);
    }
  },
  merge: async (product, formikProps, values) => {
    const { afterSave, existingProductId } = values;

    const { ok, data } = await API.publicChemicalLibrary.products.merge(product.id, {
      existing_product_id: existingProductId,
    });

    if (ok) {
      formikProps.resetForm({
        values: ProductFunctions.prepareInitialValues(data.data),
      });
      window.history.pushState(
        '',
        '',
        `/public_chemical_library/products/${data.data.id}/edit${window.location.search}`,
      );
      const productBreadcrumbs = [
        { href: '/public_chemical_library/products', text: productBreadcrumbsT('index.title') },
        { href: window.location.href, text: productBreadcrumbsT('edit.title') },
      ];
      updateAllBreadcrumbs(productBreadcrumbs);
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.merge'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.merge'), data);
    }
  },
  auxiliaryValues: product => ({
    isAwaitingItem: isDefined(product.id) && !product.is_reviewed,
    existingProductId: null,
    submitType: 'save',
    ghsCodesProperties: product.ghs_codes_properties,
    nfpaDiamondLabels: product.nfpa_diamond_labels,
    nfpaDiamondCode: product.nfpa_diamond_code,
    compoundIds: product.compound_ids,
    afterSave: () => {},
  }),
  backendValuesWhitelist: [
    'id',
    'name',
    'is_reviewed',
    'pub_chem_url',
    'manufacturer_ids',
    'storage_group_id',
    'is_controlled_substance',
    'is_narcotic',
    'schedule',
    'reporting_requirement',
    'hhop_type_ids',
    'compound_ids',
    'sds_attachments',
    'chemwatch_no',
    'ghs_code_ids',
    'explosive',
    'flammable',
    'oxidiser',
    'compressed_gas',
    'corrosive',
    'acute_toxic',
    'irritant',
    'health_hazardous',
    'environmentally_hazardous',
    'nfpa_health_hazard',
    'nfpa_flammability_hazard',
    'nfpa_instability_hazard',
    'nfpa_special_hazard',
  ],
});

export const ProductForm = withFormik({
  mapPropsToValues: props => ProductFunctions.prepareInitialValues(props.product),
  handleSubmit: ProductFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
