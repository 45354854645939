import { withFormik } from 'formik';
import { validationSchema } from './validationSchema';
import {
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../../services/utils';
import { API } from '../../../../../services/api';
import { Form } from './Form';

const t = key => I18n.t(`administration.ums.new_edit.${key}`);

const UmFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const unit = UmFunctions.prepareValuesToSubmit({ ...values });
    UmFunctions.create(unit, formikProps, values);
  },
  create: async (unit, formikProps, values) => {
    const { afterSave } = values;
    const {
      resetForm,
      setSubmitting,
      props: { modalRef, listRef },
    } = formikProps;
    const apiBase = API.administration.ums;

    const { ok, data } = await apiBase.create({
      unit,
    });
    if (ok) {
      resetForm({
        values: UmFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
      listRef.current.refresh();
      modalRef.current.hide();
    } else {
      setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({
    afterSave: () => {},
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'description',
    'um_code',
    'um_type',
    'parent_unit_id',
    'parent_conversion_value',
  ],
});

export const RelatedUmForm = withFormik({
  mapPropsToValues: props => {
    return UmFunctions.prepareInitialValues({
      ...props.unit,
      parent_unit_id: props.parent_unit_id,
      um_type: props.um_type,
      enums: props.enums,
    });
  },
  handleSubmit: UmFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
