import { withFormik } from 'formik';
import { validationSchema } from './validationSchema';
import {
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { Form } from './Form';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.withdrawal.${key}`);

const RadWithdrawalFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const withdrawal = RadWithdrawalFunctions.prepareValuesToSubmit({ ...values });
    RadWithdrawalFunctions.create(withdrawal, formikProps, values);
  },
  create: async (withdrawal, formikProps, values) => {
    const { afterSave, radInventoryId, radInventoryItemId } = values;
    const {
      resetForm,
      setSubmitting,
      props: { modalRef, listRef, resetInventoryItem },
    } = formikProps;
    const apiBase = API.radiationSafety.radWithdrawals(radInventoryId, radInventoryItemId);

    const { ok, data } = await apiBase.create({ rad_withdrawal: withdrawal });
    if (ok) {
      resetForm({
        values: RadWithdrawalFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
      await resetInventoryItem();
      listRef.current.refresh();
      modalRef.current.hide();
    } else {
      setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: radWithdrawal => ({
    afterSave: () => {},
    radInventoryId: radWithdrawal.radInventoryId,
    radInventoryItemId: radWithdrawal.radInventoryItemId,
    unitConversionValuesRatio: 1,
    remainingActivity: '',
    remainingQuantity: '',
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'activity',
    'quantity',
    'withdrawn_at',
    'activity_unit_id',
    'activity_unit_conversion_value',
    'item_activity_unit_conversion_value',
    'item_activity_unit_id',
    'quantity_unit_id',
    'item_half_life_days',
    'item_last_limits_related_change_at',
    'item_activity_after_last_change',
    'item_quantity_after_last_change',
  ],
});

export const RadWithdrawalForm = withFormik({
  mapPropsToValues: props =>
    RadWithdrawalFunctions.prepareInitialValues({
      ...props.radWithdrawal,
      radInventoryId: props.radInventoryId,
      radInventoryItemId: props.radInventoryItemId,
    }),
  handleSubmit: RadWithdrawalFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
