export const setGhsValuesFromFirstCompound = (
  setFieldValue,
  compoundIds = [],
  currentValues,
) => compoundValues => {
  const {
    ghs_code_ids: currentGhsCodesIds = [],
    ghsCodesProperties: currentGhsCodesProperties = [],
  } = currentValues;

  if (
    compoundIds.length > 0 ||
    currentGhsCodesIds.length > 0 ||
    currentGhsCodesProperties.length > 0
  ) {
    return;
  }

  const {
    ghs_code_ids: compoundGhsCodeIds = [],
    ghs_codes_properties: compoundGhsCodesProperties = [],
  } = compoundValues;

  setFieldValue('ghs_code_ids', compoundGhsCodeIds);
  setFieldValue('ghsCodesProperties', compoundGhsCodesProperties);
  compoundGhsCodesProperties.forEach(propertyName => {
    setFieldValue(propertyName, true);
  });
};
