import React, { useMemo, useRef, useState } from 'react';
import { FormSection } from '../../../form/FormSection';
import { FormSectionHeader } from '../../../form/FormSectionHeader';
import { FormSectionTitle } from '../../../form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../lists/AsyncSimpleList';
import { SimpleListDeletionLink, SimpleListLink } from '../../../lists/SimpleListLinks';
import { Modal } from '../../../modals/Modal';
import { CompoundModalContent } from './CompoundModalContent';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.lists.compounds.${key}`);

export const CompoundsList = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    compoundsApi,
    compoundsApiParams,
    extractCompoundFromFetchedData,
    translationNamespace,
    readOnly = false,
    values: { id, pchl_product_id },
  } = props;
  const listRef = useRef(null);
  const [compound, setCompound] = useState(undefined);
  const showCompoundModalRef = useRef(null);
  const showList = useMemo(() => isDefined(pchl_product_id) || isDefined(id), [
    pchl_product_id,
    id,
  ]);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title', translationNamespace)}</FormSectionTitle>
          <FormSectionHeaderLink
            hidden={readOnly}
            onClick={async () => {
              await setCompound(undefined);
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                showCompoundModalRef.current.open,
              );
            }}
          >
            {t('add', translationNamespace)}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {showList ? (
          <AsyncSimpleList
            api={compoundsApi.index}
            apiParams={compoundsApiParams}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.chemical_definitions_compound')}
            mapData={fetchedData => {
              const { id: compoundId } = fetchedData;
              const fetchedCompound = extractCompoundFromFetchedData(fetchedData);

              return [
                [t('name', translationNamespace), fetchedCompound.name],
                [t('cas', translationNamespace), fetchedCompound.cas],
                [t('ghs_codes', translationNamespace), fetchedCompound.ghs_codes],
                [t('nfpa_diamond_code', translationNamespace), fetchedCompound.nfpa_diamond_code],
                <SimpleListLink
                  key="show"
                  disabled={isSubmitting}
                  onClick={async () => {
                    const { ok, data } = await compoundsApi.show(compoundId, compoundsApiParams);
                    if (ok) {
                      const compoundData = extractCompoundFromFetchedData(data.data);
                      await setCompound(compoundData);

                      if (readOnly) {
                        showCompoundModalRef.current.open();
                      } else {
                        await handleSubmitWithAfterSave(
                          setFieldValue,
                          handleSubmit,
                          showCompoundModalRef.current.open,
                        );
                      }
                    } else {
                      showBackendErrorMessage(t('flash.error.save', translationNamespace), data);
                    }
                  }}
                >
                  {t('show', translationNamespace)}
                </SimpleListLink>,
                <SimpleListDeletionLink
                  key="delete"
                  modelName={t('compound', translationNamespace)}
                  hidden={readOnly}
                  onSubmit={async () => {
                    const { ok, data } = await compoundsApi.destroy(compoundId, compoundsApiParams);
                    if (ok) {
                      listRef.current.refresh();
                      showSuccessMessage(t('flash.success.remove', translationNamespace));
                    } else {
                      showBackendErrorMessage(t('flash.error.remove', translationNamespace), data);
                    }
                  }}
                >
                  {t('remove', translationNamespace)}
                </SimpleListDeletionLink>,
              ];
            }}
          />
        ) : (
          <EmptyListPlaceholder />
        )}
      </FormSection>
      <Modal
        title={I18n.t(
          `${translationNamespace}.new_edit.modals.compound.${compound ? 'show' : 'add'}.title`,
        )}
        ref={showCompoundModalRef}
      >
        {modalProps => (
          <CompoundModalContent {...props} {...modalProps} compound={compound} listRef={listRef} />
        )}
      </Modal>
    </>
  );
};
