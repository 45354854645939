import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { AddWasteCollectionModalContent } from './WasteCollection/AddWasteCollectionModalContent';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.${key}`);
const listT = key => t(`lists.waste_collections.${key}`);
const modelT = key => I18n.t(`activerecord.attributes.waste_chemical_item.${key}`);

export const WasteCollections = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id, radInventoryId },
  } = props;

  const addWasteCollectionModalRef = useRef(null);
  const listRef = useRef(null);

  const computeReopenable = fetchedData => {
    const wasteCollectionsCount = fetchedData.length;
    setFieldValue('reopenable', wasteCollectionsCount === 0);
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{listT('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addWasteCollectionModalRef.current.open,
              );
            }}
          >
            {listT('add_link')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.radiationSafety.radInventoryItems(radInventoryId).wasteCollections(id).index}
            ref={listRef}
            dataKey="data"
            mapRowHrefs={({ attributes: attribute }) =>
              `/waste_management/waste_collections/${attribute.waste_collection.id}/edit`
            }
            resourceName={I18n.t('resource_names.rad_inventory_items_waste_collection')}
            mapData={({ attributes }) => [
              [modelT('identifier'), attributes.waste_collection.identifier],
              [modelT('start_date'), attributes.waste_collection.start_date],
              [
                modelT('waste_chemical_name_id'),
                attributes.waste_collection.waste_chemical_name_id,
              ],
              <SimpleListDeletionLink
                key="delete"
                modelName={listT('model_name')}
                modalWarning={listT('unlink_waste_collection_warning')}
                modalConfirmationButtonText={listT('unlink_waste_collection_confirmation_button')}
                onSubmit={async () => {
                  const { ok, data } = await API.radiationSafety
                    .radInventoryItems(radInventoryId)
                    .wasteCollections(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(listT('flash.success.delete'));
                  } else {
                    showBackendErrorMessage(listT('flash.error.delete'), data);
                  }
                }}
              >
                {listT('delete_link')}
              </SimpleListDeletionLink>,
            ]}
            onDataFetched={computeReopenable}
          />
        )}
      </FormSection>
      <Modal title={t('modals.add_waste_collection.title')} ref={addWasteCollectionModalRef}>
        {modalProps => (
          <AddWasteCollectionModalContent {...modalProps} {...props} listRef={listRef} />
        )}
      </Modal>
    </>
  );
};
