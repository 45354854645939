import React from 'react';
import styled from 'styled-components';
import { FastField } from 'formik';
import { fieldPropDefaultTransformation } from '../../../../services/fieldUtils';
import { fontSize } from '../../../../assets/styles/typography';
import { colors } from '../../../../assets/styles/colors';
import {
  GHS_CODE_PROPERTY_NAME_ICON_MAP,
  ghsCodePropertyIconsStyle,
  GhsPictogramLabel,
} from './shared';

const Input = styled.input`
  font-size: ${fontSize.textMd};
  color: ${colors.inputTextColor};
  padding: 0 8px;
  height: 36px;
  background: ${props => (props.disabled ? colors.disabled : colors.white)};
  background-clip: padding-box;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 4px;
  &:focus {
    border: none;
    box-shadow: none;
    outline: 0;
  }
`;

const GHS_CODE_PROPERTY_NAMES_FIRST_COLUMN = [
  'explosive',
  'flammable',
  'oxidiser',
  'compressed_gas',
  'corrosive',
];

const GHS_CODE_PROPERTY_NAMES_SECOND_COLUMN = [
  'acute_toxic',
  'irritant',
  'health_hazardous',
  'environmentally_hazardous',
];

const GhsCodePropertyInputWrapper = props => {
  const { name, label, children, propertyName } = props;

  return (
    <div className="w-100 mb-3">
      <div className="w-100 padding-0 row mx-0">
        <GhsPictogramLabel
          name={name}
          className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex pl-0"
          inputId={`${name}-checkbox`}
        >
          {label}
        </GhsPictogramLabel>
        <div className="col-12 col-sm-8 col-md-8 col-lg-4 d-flex px-0">
          {children}
          <div className="ml-2 pl-2 d-flex justify-content-center">
            <img
              src={GHS_CODE_PROPERTY_NAME_ICON_MAP[propertyName]}
              alt={`${propertyName} pictogram`}
              style={ghsCodePropertyIconsStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const renderGhsCodePropertyInput = propertyName => (
  <FastField name={propertyName}>
    {formikProps => {
      const {
        field: { name, value },
      } = formikProps;
      return (
        <GhsCodePropertyInputWrapper
          propertyName={propertyName}
          name={name}
          label={I18n.t(`activerecord.attributes.chemical_definition.${propertyName}`)}
        >
          <Input
            {...fieldPropDefaultTransformation(formikProps)}
            id={`${name}-checkbox`}
            type="checkbox"
            checked={String(value) === 'true'}
            {...formikProps}
          />
        </GhsCodePropertyInputWrapper>
      );
    }}
  </FastField>
);

const GhsCodesPropertiesColumn = ({ ghsCodesPropertyNames }) => (
  <div className="col-md-4">
    {ghsCodesPropertyNames.map(propertyName => {
      return renderGhsCodePropertyInput(propertyName);
    })}
  </div>
);

export const EditableGhsCodesProperties = () => {
  return (
    <div className="w-100 mb-3 row">
      <div className="col-md-2" />
      <GhsCodesPropertiesColumn ghsCodesPropertyNames={GHS_CODE_PROPERTY_NAMES_FIRST_COLUMN} />
      <GhsCodesPropertiesColumn ghsCodesPropertyNames={GHS_CODE_PROPERTY_NAMES_SECOND_COLUMN} />
    </div>
  );
};
