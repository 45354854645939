import React from 'react';
import {
  GHS_CODE_PROPERTY_NAME_ICON_MAP,
  ghsCodePropertyIconsStyle,
  GhsPictogramLabel,
} from './shared';

const t = (key, compoundI18nPrefix) =>
  I18n.t(`activerecord.attributes.${compoundI18nPrefix}.${key}`);

export const ReadOnlyGhsCodesProperties = ({ ghsCodesProperties = [], compoundI18nPrefix }) => {
  return (
    <div className="mt-3">
      {ghsCodesProperties.map(propertyName => (
        <div className="w-100 mb-3">
          <div className="w-100 padding-0 row mx-0">
            <GhsPictogramLabel className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex pl-0">
              {t(propertyName, compoundI18nPrefix)}
            </GhsPictogramLabel>
            <div className="col-12 col-sm-8 col-md-8 col-lg-4 d-flex px-0">
              <div className="d-flex justify-content-center">
                <img
                  src={GHS_CODE_PROPERTY_NAME_ICON_MAP[propertyName]}
                  alt={`${propertyName} pictogram`}
                  style={ghsCodePropertyIconsStyle}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
