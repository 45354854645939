import { useEffect } from 'react';
import { some } from 'lodash';
import { isDefined, saluteRound } from '../../../../services/utils';
import { applyHalfLife } from '../../utils';

const calcRemainingValues = (
  baseActivity,
  baseQuantity,
  halfLifeDays,
  startedAt,
  splitActivity,
  splittedAt,
  unitConversionValuesRatio,
) => {
  if (
    !isDefined(baseActivity) ||
    !isDefined(baseQuantity) ||
    !isDefined(startedAt) ||
    !isDefined(splitActivity) ||
    !isDefined(splittedAt)
  ) {
    return {
      remainingActivity: '',
      remainingQuantity: '',
      quantity: '',
    };
  }
  if (halfLifeDays === 0) {
    return {
      remainingActivity: 0.0,
      remainingQuantity: 0.0,
      quantity: 0.0,
    };
  }

  const convertedSplitActivity = splitActivity * unitConversionValuesRatio;
  if (!isDefined(halfLifeDays)) {
    const splittedQuantity = baseQuantity * (convertedSplitActivity / baseActivity);

    return {
      remainingActivity: saluteRound(baseActivity - convertedSplitActivity),
      remainingQuantity: saluteRound(baseQuantity - splittedQuantity),
      quantity: saluteRound(splittedQuantity),
    };
  }

  const activityBeforeSplit = applyHalfLife(baseActivity, halfLifeDays, startedAt, splittedAt);

  const splittedQuantity = baseQuantity * (convertedSplitActivity / activityBeforeSplit);
  const resultQuantity = baseQuantity - splittedQuantity;

  const activityAfterSplit = activityBeforeSplit - convertedSplitActivity;

  return {
    remainingActivity: saluteRound(activityAfterSplit),
    remainingQuantity: saluteRound(resultQuantity),
    quantity: saluteRound(splittedQuantity),
  };
};

export const useRemainingValues = props => {
  const {
    setFieldValue,
    errors,
    values: {
      splitted_date_time,
      activity,
      activity_unit_id,
      item_half_life_days,
      item_last_limits_related_change_at,
      item_activity_after_last_change,
      item_quantity_after_last_change,
      unitConversionValuesRatio,
    },
  } = props;

  const errorKeys = Object.keys(errors);

  useEffect(() => {
    if (some(['activity', 'splitted_date_time'], key => errorKeys.includes(key))) {
      setFieldValue('remainingActivity', '');
      setFieldValue('remainingQuantity', '');
    } else {
      const { remainingActivity, remainingQuantity, quantity } = calcRemainingValues(
        item_activity_after_last_change,
        item_quantity_after_last_change,
        item_half_life_days,
        item_last_limits_related_change_at,
        activity,
        splitted_date_time,
        unitConversionValuesRatio,
      );

      setFieldValue('remainingActivity', remainingActivity);
      setFieldValue('remainingQuantity', remainingQuantity);
      setFieldValue('quantity', quantity);
    }
  }, [
    item_activity_after_last_change,
    item_quantity_after_last_change,
    item_half_life_days,
    item_last_limits_related_change_at,
    activity,
    activity_unit_id,
    splitted_date_time,
    JSON.stringify(errorKeys),
  ]);
};
