import React, { useRef, useState } from 'react';
import { FormSection } from '../../components/form/FormSection';
import { FormSectionHeader } from '../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../components/form/FormSectionHeaderLinks';
import { API } from '../../services/api';
import { showBackendErrorMessage, showSuccessMessage } from '../../services/utils';
import { AsyncSimpleList } from '../../components/lists/AsyncSimpleList';
import { Modal } from '../../components/modals/Modal';
import { SimpleListDeletionLink, SimpleListLink } from '../../components/lists/SimpleListLinks';
import { DeletionModalContent } from '../../components/modals/DeletionModalContent';
import { FormHeader } from '../../components/form/FormHeader';

const t = key => I18n.t(`administration.ums.new_edit.${key}`);
const umT = key => I18n.t(`activerecord.attributes.um.${key}`);

const UmsList = () => {
  const listRef = useRef(null);
  const deletionModalRef = useRef(null);
  const [idToDelete, setIdToDelete] = useState(null);

  return (
    <>
      <FormHeader title={t('title.index')} />
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('default_list_title')}</FormSectionTitle>
          <FormSectionHeaderLink
            onClick={async () => {
              window.location.href = '/administration/ums/new';
            }}
          >
            {t('add_option')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        <AsyncSimpleList
          ref={listRef}
          api={() => API.administration.ums.index({})}
          dataKey="data"
          resourceName={I18n.t('resource_names.ums')}
          isSortable
          reorderItemApi={API.administration.ums.reorder}
          mapData={({ attributes }) => [
            [umT('um_code'), attributes.um_code],
            [umT('description'), attributes.description],
            [umT('um_type'), umT(`um_types.${attributes.um_type}`)],
            <SimpleListLink
              key="view"
              onClick={async () => {
                window.location.href = `/administration/ums/${attributes.id}/edit`;
              }}
            >
              {t('edit_option')}
            </SimpleListLink>,
            <SimpleListDeletionLink
              modal={false}
              key="remove"
              onSubmit={async () => {
                setIdToDelete(attributes.id);
                deletionModalRef.current.open();
              }}
            >
              {t('remove_option')}
            </SimpleListDeletionLink>,
          ]}
        />
      </FormSection>
      <Modal ref={deletionModalRef}>
        <DeletionModalContent
          modalRef={deletionModalRef}
          modelName={umT('model_name')}
          onSubmit={async () => {
            const { ok, data } = await API.administration.ums.destroy(idToDelete);
            if (ok) {
              listRef.current.refresh();
              showSuccessMessage(t('flash.success.remove'));
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
            deletionModalRef.current.hide();
          }}
        />
      </Modal>
    </>
  );
};
export default UmsList;
