import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { ModalSection } from '../../../components/modals/ModalSection';
import { SelectorField } from '../../../components/fields/SelectorField';
import { Modal } from '../../../components/modals/Modal';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';

const t = key => I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.${key}`);
const createModalT = key =>
  I18n.t(
    `chemical_safety.chemical_libraries.chemical_definitions.new_edit.modals.create_modal.${key}`,
  );

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    connectedToPchl,
    values: { id },
  } = props;

  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  const createModalRef = useRef(null);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.chemical_definition')}
            onSubmit={async () => {
              const { ok, data } = await API.chemicalSafety.chemicalDefinitions.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy'));
                window.location.href = '/chemical_safety/chemical_definitions';
              } else {
                showBackendErrorMessage(t('flash.error.destroy'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        {!(isDefined(id) && connectedToPchl) && (
          <FormFooterRightPart>
            <BrandButton
              onClick={async () => {
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }}
            >
              {t('footer.save')}
            </BrandButton>
          </FormFooterRightPart>
        )}
      </FormFooter>
      {/* Currently not in use */}
      <Modal title={createModalT('title')} ref={createModalRef}>
        <ModalSection>
          <FastField
            name="submitType"
            component={SelectorField}
            displayMode={fieldDisplayModes.GROWING_WIDTH_INPUT}
            options={[
              { value: 'save', data: createModalT('submit_type.save') },
              {
                value: 'save_and_create_awaiting_item_in_pchl',
                data: createModalT('submit_type.save_and_create_awaiting_item_in_pchl'),
              },
            ]}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <BrandButton
                onClick={async () => {
                  createModalRef.current.hide();
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {createModalT('save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
