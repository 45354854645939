import React, { useMemo, useRef } from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { FormHeader } from '../../../../components/form/FormHeader';
import {
  PubChemLink,
  SdsHeaderLink,
} from '../../../../components/other/chemicalSafety/FormHeaderLinks';
import { TextField } from '../../../../components/fields/TextField';
import { FormSection } from '../../../../components/form/FormSection';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncMultiSelectorField } from '../../../../components/fields/AsyncMultiSelectorField';
import { FilesField } from '../../../../components/fields/FilesField';
import { CheckBoxField } from '../../../../components/fields/CheckBoxField';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { isDefined } from '../../../../services/utils';
import { CompoundsList } from '../../../../components/other/chemicalSafety/Compounds/Compounds';
import { Footer } from './Footer';
import { setGhsValuesFromFirstCompound } from './setFormValuesHelper';
import { EditableGhsCodesProperties } from '../../../../components/other/chemicalSafety/GhsCodesProperties/EditableGhsCodesProperties';
import { NfpaProperties } from '../../../../components/other/chemicalSafety/NfpaProperties/NfpaProperties';
import { setNfpaValuesFromFirstCompound } from '../../../ChemicalDefinition/Form/setFormValuesHelper';
import { Modal } from '../../../../components/modals/Modal';
import { AddManufacturerModalContent } from '../../../../components/other/chemicalSafety/Manufacturers/AddManufacturerModalContent';
import { DefaultLink } from '../../../../components/links/DefaultLink';

const t = (key, isAwaitingItem = false) =>
  I18n.t(
    `public_chemical_library.${
      isAwaitingItem ? 'awaiting_items.edit' : 'products.new_edit'
    }.${key}`,
  );

export const Form = props => {
  const {
    values: {
      id,
      pub_chem_url,
      ghs_code_ids,
      ghsCodesProperties,
      sds_attachments,
      isAwaitingItem,
      chemwatch_no,
      compoundIds,
      nfpa_health_hazard,
      nfpa_flammability_hazard,
      nfpa_instability_hazard,
      nfpa_special_hazard,
      manufacturer_ids,
    },
    setFieldValue,
    enums: { reportingRequirementOptions, scheduleOptions },
    currentUser,
  } = props;

  const addManufacturerModalRef = useRef(null);

  const sdsAttachmentUrl = useMemo(() => {
    const { url } = sds_attachments?.[0] || {};
    return url;
  }, [sds_attachments]);

  const compoundsApiParams = useMemo(() => {
    return { product_id: id };
  }, [id]);

  return (
    <form>
      <FormHeader
        title={t(isDefined(id) ? 'title.edit' : 'title.new', isAwaitingItem)}
        additionalActions={[
          <SdsHeaderLink
            id={id}
            sdsAttachmentUrl={sdsAttachmentUrl}
            chemwatchNo={chemwatch_no}
            generateSdsApi={API.publicChemicalLibrary.products.generateSds}
            userEmail={currentUser.email}
            translationNamespace="public_chemical_library.products"
          />,
          <PubChemLink
            id={id}
            pubChemUrl={pub_chem_url}
            translationNamespace="public_chemical_library.products"
          />,
        ]}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.public_chemical_library/product',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.public_chemical_library.product'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField
            name="manufacturer_ids"
            api={API.publicChemicalLibrary.selectors.manufacturers.index}
            selectionApi={API.publicChemicalLibrary.selectors.manufacturers.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
            additionalContent={
              <DefaultLink
                onClick={() => {
                  addManufacturerModalRef.current.open();
                }}
              >
                {t('links.add_manufacturer')}
              </DefaultLink>
            }
          />
          <FastField
            name="storage_group_id"
            api={API.publicChemicalLibrary.selectors.storageGroups.index}
            selectionApi={API.publicChemicalLibrary.selectors.storageGroups.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            isClearable
          />
          <FastField
            name="hhop_type_ids"
            api={API.publicChemicalLibrary.selectors.hhopTypes.index}
            selectionApi={API.publicChemicalLibrary.selectors.hhopTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="sds_attachments" component={FilesField} />
        </FormSection>
        <CompoundsList
          {...props}
          compoundsApi={API.publicChemicalLibrary.compoundsProducts}
          compoundsApiParams={compoundsApiParams}
          addableCompoundsApi={API.publicChemicalLibrary.selectors.addableCompounds}
          addableCompoundsApiIndexParams={{ product_id: id }}
          createProductsCompoundApi={API.publicChemicalLibrary.compoundsProducts.create}
          getCreateProductsCompoundApiParams={selectedCompoundId => ({
            compound_id: selectedCompoundId,
            product_id: id,
          })}
          extractCompoundFromFetchedData={fetchedData => fetchedData.compound}
          translationNamespace="public_chemical_library.products"
          compoundI18nPrefix="public_chemical_library/compound"
          setProductGhsValuesFromFirstCompound={setGhsValuesFromFirstCompound(
            setFieldValue,
            compoundIds,
            {
              ghs_code_ids,
              ghsCodesProperties,
            },
          )}
          setProductNfpaValuesFromFirstCompound={setNfpaValuesFromFirstCompound(
            setFieldValue,
            compoundIds,
            {
              nfpa_health_hazard,
              nfpa_flammability_hazard,
              nfpa_instability_hazard,
              nfpa_special_hazard,
            },
          )}
        />
        <FormSection title={t('sections.ghs_classification')}>
          <FastField
            name="ghs_code_ids"
            api={API.publicChemicalLibrary.selectors.ghsCodes.index}
            selectionApi={API.publicChemicalLibrary.selectors.ghsCodes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <EditableGhsCodesProperties />
        </FormSection>
        <FormSection title={t('sections.nfpa_classification')}>
          <NfpaProperties {...props} />
        </FormSection>
        <FormSection title={t('sections.details')}>
          <FastField name="is_controlled_substance" component={CheckBoxField} />
          <FastField
            name="reporting_requirement"
            options={mapToSelectorOptions(reportingRequirementOptions, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="is_narcotic" component={CheckBoxField} />
          <FastField
            name="schedule"
            options={mapToSelectorOptions(scheduleOptions, 'header', 'value')}
            component={SelectorField}
          />
        </FormSection>
        <Footer {...props} />
      </DefaultAttributesGroup>
      <Modal title={t('modals.add_manufacturer.title')} ref={addManufacturerModalRef}>
        {modalProps => (
          <AddManufacturerModalContent
            setNewManufacturerId={manufacturerId => {
              setFieldValue('manufacturer_ids', [...manufacturer_ids, manufacturerId]);
            }}
            translationPath="public_chemical_library.products.new_edit.modals.add_manufacturer"
            manufacturersApi={API.publicChemicalLibrary.selectors.manufacturers}
            {...modalProps}
          />
        )}
      </Modal>
    </form>
  );
};
