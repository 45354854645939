import acuteToxic from './acute_toxic.svg';
import compressedGas from './compressed_gas.svg';
import corrosive from './corrosive.svg';
import environmentallyHazardous from './environmentally_hazardous.svg';
import explosive from './explosive.svg';
import flammable from './flammable.svg';
import healthHazardous from './health_hazardous.svg';
import irritant from './irritant.svg';
import oxidiser from './oxidiser.svg';

export {
  acuteToxic,
  compressedGas,
  corrosive,
  environmentallyHazardous,
  explosive,
  flammable,
  healthHazardous,
  irritant,
  oxidiser,
};
