import React, { useRef } from 'react';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSection } from '../../../components/form/FormSection';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { API } from '../../../services/api';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { Modal } from '../../../components/modals/Modal';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { RelatedUm } from './RelatedUm';

const t = key => I18n.t(`administration.ums.new_edit.${key}`);
const listT = key => t(`lists.related_ums.${key}`);
const umT = key => I18n.t(`activerecord.attributes.um.${key}`);

export const RelatedUmsList = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    enums,
    values: { id, um_type },
  } = props;
  const listRef = useRef(null);
  const modalRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{listT('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            hidden={!id}
            onClick={async () => {
              const afterSave = () => modalRef.current.open();
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {listT('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            ref={listRef}
            api={() => API.administration.ums.index({ parent_unit_id: id })}
            dataKey="data"
            resourceName={I18n.t('resource_names.um')}
            mapRowHrefs={({ attributes }) =>
              attributes.id ? `/administration/ums/${attributes.id}/edit` : null
            }
            mapData={({ attributes }) => [
              [umT('um_code'), attributes.um_code],
              [umT('description'), attributes.description],
              [umT('parent_conversion_value'), attributes.parent_conversion_value],
              <SimpleListDeletionLink
                key="delete"
                disabled={isSubmitting}
                hidden={!id}
                modelName={listT('model_name')}
                onSubmit={async () => {
                  const apiBase = API.administration.ums;
                  const { ok, data } = await apiBase.destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(listT('flash.success.delete'));
                  } else {
                    showBackendErrorMessage(listT('flash.error.delete'), data);
                  }
                }}
              >
                {listT('delete')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal ref={modalRef} title={t('modals.related_ums.title')}>
        <RelatedUm
          modalRef={modalRef}
          parentUmId={id}
          umType={um_type}
          enums={enums}
          listRef={listRef}
          {...props}
        />
      </Modal>
    </>
  );
};
