import { withFormik } from 'formik';
import { validationSchema } from './validationSchema';
import {
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { Form } from './Form';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.split.${key}`);

const RadInventoryItemSplitFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const split = RadInventoryItemSplitFunctions.prepareValuesToSubmit({ ...values });
    RadInventoryItemSplitFunctions.create(split, formikProps, values);
  },
  create: async (split, formikProps, values) => {
    const {
      afterSave,
      radInventoryId,
      radInventoryItemId,
      showMoveInventoryItemSection,
      target_rad_inventory_id,
      target_rad_license_item_assignment_id,
    } = values;
    const {
      resetForm,
      setSubmitting,
      props: { modalRef, listRef, resetInventoryItem },
    } = formikProps;
    const apiBase = API.radiationSafety.radInventoryItemSplits(radInventoryId, radInventoryItemId);
    const additionalParams = {
      move_to_another_inventory: showMoveInventoryItemSection,
      target_rad_inventory_id,
      target_rad_license_item_assignment_id,
    };

    const { ok, data } = await apiBase.create({
      rad_inventory_item_split: split,
      ...additionalParams,
    });
    if (ok) {
      resetForm({
        values: RadInventoryItemSplitFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
      await resetInventoryItem();
      listRef.current.refresh();
      modalRef.current.hide();
    } else {
      setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: radInventoryItemSplit => ({
    afterSave: () => {},
    radInventoryId: radInventoryItemSplit.radInventoryId,
    radInventoryItemId: radInventoryItemSplit.radInventoryItemId,
    unitConversionValuesRatio: 1,
    remainingActivity: '',
    remainingQuantity: '',
    showMoveInventoryItemSection: false,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'activity',
    'quantity',
    'splitted_date_time',
    'activity_unit_id',
    'quantity_unit_id',
    'activity_unit_conversion_value',
    'item_activity_unit_conversion_value',
    'item_activity_unit_id',
    'item_half_life_days',
    'item_last_limits_related_change_at',
    'item_activity_after_last_change',
    'item_quantity_after_last_change',
  ],
});

export const RadInventoryItemSplitForm = withFormik({
  mapPropsToValues: props =>
    RadInventoryItemSplitFunctions.prepareInitialValues({
      ...props.radInventoryItemSplit,
      radInventoryId: props.radInventoryId,
      radInventoryItemId: props.radInventoryItemId,
    }),
  handleSubmit: RadInventoryItemSplitFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
