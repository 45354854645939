import React from 'react';
import { LinkBase } from './auxiliary/LinkBase';

export const DefaultLink = ({
  id,
  onClick,
  href,
  children,
  hidden,
  disabled,
  target,
  style = {},
}) => (
  <LinkBase
    id={id}
    onClick={onClick}
    hidden={hidden}
    href={href}
    style={style}
    disabled={disabled}
    target={target}
  >
    {children}
  </LinkBase>
);
