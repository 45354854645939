import React from 'react';
import { FastField } from 'formik';
import { FormSectionTitle } from '../../../../form/FormSectionTitle';
import { ReadOnlyGhsCodesProperties } from '../../GhsCodesProperties/ReadOnlyGhsCodesProperties';
import { TextField } from '../../../../fields/TextField';
import { DefaultAttributesGroup } from '../../../../form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { NfpaProperties } from '../../NfpaProperties/NfpaProperties';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.modals.compound.${key}`);

export const PresentationFields = props => {
  const {
    compoundI18nPrefix,
    translationNamespace,
    values: { ghs_codes_properties },
  } = props;

  return (
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        resourceName: I18n.t('resource_names.compound'),
        labelI18nKeyPrefix: `activerecord.attributes.${compoundI18nPrefix}`,
        displayMode: fieldDisplayModes.WIDE_FORM_ROW,
        disabled: true,
      }}
    >
      <FastField name="cas" component={TextField} />
      <FormSectionTitle>{t('sections.ghs_classification', translationNamespace)}</FormSectionTitle>
      <FastField name="ghs_codes" component={TextField} />
      <ReadOnlyGhsCodesProperties
        ghsCodesProperties={ghs_codes_properties}
        compoundI18nPrefix={compoundI18nPrefix}
      />
      <FormSectionTitle>
        {t('sections.nfpa_hazard_classification', translationNamespace)}
      </FormSectionTitle>
      <NfpaProperties {...props} disabled />
    </DefaultAttributesGroup>
  );
};
