import { useState, useEffect } from 'react';
import { isDefined } from '../../../../../../community_portal/assets/helpers/GeneralHelper';
import { showErrorMessage } from '../../../../../services/utils';

const t = (key, translationNamespace) =>
  I18n.t(`${translationNamespace}.new_edit.modals.compound.${key}`);

export const useDetailsLoader = props => {
  const {
    setFieldValue,
    resetForm,
    addableCompoundsApi,
    translationNamespace,
    values: { cid, presentationMode, backendValuesWhitelist },
  } = props;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      if (isDefined(cid) && !presentationMode) {
        setLoading(true);
        const { ok, data } = await addableCompoundsApi.show(cid);
        let extractedData = data.data;
        if (extractedData.attributes) extractedData = extractedData.attributes;
        if (ok) {
          backendValuesWhitelist.forEach(key => {
            setFieldValue(key, extractedData?.[key]);
          });
        } else {
          showErrorMessage(t('flash.errors.fetch_details', translationNamespace));
          resetForm();
        }
        setLoading(false);
      }
    })();
  }, [cid, presentationMode, backendValuesWhitelist]);

  return loading;
};
