import React from 'react';
import { colors } from '../../../assets/styles/colors';

const disabledLinkStyle = {
  pointerEvents: 'none',
  cursor: 'default',
  color: colors.disabledDarker,
};

export const LinkBase = props => {
  const { id, onClick, href = '#', children, hidden, disabled, target, className } = props;
  let { style = {} } = props;

  if (disabled) style = { ...style, ...disabledLinkStyle };

  return (
    <a
      id={id}
      href={href}
      target={target}
      className={className}
      style={style}
      hidden={hidden}
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
    >
      {children}
    </a>
  );
};
