import React, { useEffect, useState } from 'react';
import { API } from '../../../../services/api';
import { Loader } from '../../../../components/Loader';
import { RelatedUmForm } from './Form';

export const RelatedUm = props => {
  const { enums, parentUmId, umType, modalRef, listRef } = props;
  const [fetching, setFetching] = useState(true);
  const [um, setUm] = useState({});

  useEffect(() => {
    (async () => {
      const { ok, data } = await API.administration.ums.new();
      if (ok) {
        await setUm(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }

  return (
    <RelatedUmForm
      parent_unit_id={parentUmId}
      unit={um}
      um_type={umType}
      enums={enums}
      modalRef={modalRef}
      listRef={listRef}
    />
  );
};
