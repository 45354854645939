import React, { useState, useCallback } from 'react';
import { FastField } from 'formik';
import { ModalSection } from '../../../components/modals/ModalSection';
import { ModalHint } from '../../../components/modals/ModalHint';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { Loader } from '../../../components/Loader';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import {
  defaultHandleSubmit,
  handleSubmitWithAfterSave,
  setInitialFlashMessageForNextPage,
} from '../../../services/utils';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.dispose.${key}`);

export const DisposeModalContent = props => {
  const {
    modalRef,
    setFieldValue,
    handleSubmit,
    values: { id, createWasteCollection },
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const hide = useCallback(() => {
    modalRef.current.hide();
  }, [modalRef]);

  const confirmText = createWasteCollection
    ? t('confirm_and_create_waste_collection')
    : t('confirm');

  return (
    <ModalSection>
      <ModalHint>{t('hint')}</ModalHint>
      <FastField
        name="createWasteCollection"
        label={t('create_waste_collection')}
        displayMode={fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_LABEL}
        component={CheckBoxField}
      />
      <FormFooter>
        <FormFooterRightPart>
          <CancellationLink onClick={hide} />
          <BrandButton
            onClick={async () => {
              setIsLoading(true);
              await setFieldValue('submitType', 'dispose');
              if (createWasteCollection) {
                const afterSave = () => {
                  setInitialFlashMessageForNextPage(t('flash.success.dispose'));
                  setTimeout(
                    (window.location.href = `/waste_management/waste_collections/new?rad_inventory_item_id=${id}`),
                    1000,
                  );
                };
                await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              } else {
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }
              hide();
              setIsLoading(false);
            }}
          >
            {isLoading ? <Loader /> : confirmText}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
