import yup from '../../../../components/form/CustomYupValidator';
import { transformNaNValue } from '../../../../../services/formHelper';
import {
  currentDateTime,
  dateFormatter,
  getConvertedMoment,
  newMoment,
} from '../../../../services/dateFormatUtils';

const t = (key, params = {}) =>
  I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.split.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  activity: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  remainingQuantity: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
  remainingActivity: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
  splitted_date_time: yup
    .string()
    .nullable()
    .test(
      'splitted_date_time_cannot_be_later_than_item_last_limits_related_change_at',
      t('splitted_date_time_cannot_be_later_than_item_last_limits_related_change_at'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function _(splitted_date_time) {
        const lastChangeDate = this.parent.item_last_limits_related_change_at;

        return splitted_date_time && lastChangeDate
          ? getConvertedMoment(splitted_date_time).isSameOrAfter(getConvertedMoment(lastChangeDate))
          : true;
      },
    )
    .test(
      'splitted_date_time_cannot_be_later_than_current_time',
      t('splitted_date_time_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function _(splitted_date_time) {
        return splitted_date_time
          ? getConvertedMoment(splitted_date_time).isSameOrBefore(newMoment())
          : true;
      },
    )
    .required(I18n.t('validations.required')),
});
