import React from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { defaultHandleSubmit } from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { NumericAndUnitFields } from '../../../../components/fields/compositeFields/NumericAndUnitFields';
import { useRemainingValues } from './useRemainingValues';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.withdrawal.${key}`);

export const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    modalRef,
    values: { item_activity_unit_id, item_activity_unit_conversion_value },
  } = props;

  useRemainingValues(props);

  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            labelI18nKeyPrefix: 'activerecord.attributes.rad_withdrawal',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_withdrawal'),
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          }}
        >
          <FastField name="withdrawn_at" required component={DateTimeField} />
          <FastField
            name="remainingActivity"
            disabled
            component={NumericAndUnitFields}
            unitName="item_activity_unit_id"
            label={t('additional_fields.remaining_activity')}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingQuantity"
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            label={t('additional_fields.remaining_quantity')}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="activity"
            required
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            unitProps={{
              required: true,
              disabled: false,
              onChange: e => {
                const newParentConversionValue = e.data.parent_conversion_value || 1;
                const itemUnitConversionValue = item_activity_unit_conversion_value || 1;
                setFieldValue(
                  'unitConversionValuesRatio',
                  newParentConversionValue / itemUnitConversionValue,
                );
                setFieldValue('activity_unit_id', e.value);
              },
            }}
            unitApiParams={{ um_type: ['radioactivity'], um_family: item_activity_unit_id }}
          />
          <FastField
            name="quantity"
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            unitApiParams={{ um_type: ['mass'] }}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={() => modalRef.current.hide()} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
