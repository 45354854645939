import yup from '../../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  um_code: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  parent_conversion_value: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
