import React, { useState, useEffect } from 'react';
import { isDefined } from '../../../services/utils';
import { API } from '../../../services/api';
import { Loader } from '../../../components/Loader';
import { ProductForm } from './Form';

export const Product = props => {
  const { id } = props;
  const [fetching, setFetching] = useState(true);
  const [product, setProduct] = useState({});
  useEffect(() => {
    (async () => {
      const api = API.publicChemicalLibrary.products[isDefined(id) ? 'show' : 'new'];
      const { ok, data } = await api(id);
      if (ok) {
        await setProduct(data.data);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <ProductForm {...props} product={product} />;
};
export default Product;
