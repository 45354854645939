import React, { useEffect } from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { ModalSection } from '../../../components/modals/ModalSection';
import { TextField } from '../../../components/fields/TextField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { NumericField } from '../../../components/fields/NumericField';
import { renderCustomInput } from '../../../components/fields/auxiliary/renderCustomInput';

const t = (key, args = {}) => I18n.t(`administration.simple_dictionaries.edit.${key}`, args);

export const SimpleDictionaryOptionForm = props => {
  const {
    values: { id, customFields, maxPositionValue },
    dictionary,
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    listRef,
    hasPosition,
  } = props;

  useEffect(() => {
    setFieldValue('hasPosition', hasPosition);
  }, [hasPosition]);

  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'administration.simple_dictionaries.edit.form_fields',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.simple_dictionary_options'),
        }}
      >
        <ModalSection>
          <FastField name="name" required component={TextField} />
          {customFields?.map((field, index) => (
            <FastField
              name={`customFields.${index}.value`}
              label={field.label}
              required={field.required}
              hint={field.hint}
              placeholder={field.placeholder}
              config={field.config}
              options={field.options && mapToSelectorOptions(field.options, 'header', 'value')}
              component={renderCustomInput(field.type)}
            />
          ))}
          {hasPosition && (
            <FastField
              name="position"
              label={t('position')}
              roundPrecision={0}
              component={NumericField}
              warning={t('position_warning', { max_position_value: maxPositionValue })}
            />
          )}
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!id}
                modelName={t('dictionary_option')}
                onSubmit={async () => {
                  const { ok, data } = await API.administration.simpleDictionaryOptions.destroy(
                    id,
                    { dictionary },
                  );
                  if (ok) {
                    showSuccessMessage(t('flash.success.remove'));
                    hide();
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('save_option')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
