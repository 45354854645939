import yup from '../../../../components/form/CustomYupValidator';

const t = key => I18n.t(`public_chemical_library.products.new_edit.validations.${key}`);

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  existingProductId: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'merge',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_merge_product')),
    }),
});
