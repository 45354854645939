import React, { useEffect, useState } from 'react';
import { API } from '../../../services/api';
import { isDefined } from '../../../services/utils';
import { Loader } from '../../../components/Loader';
import { RadInventoryItemSplitForm } from './Form';

export const RadInventoryItemSplit = props => {
  const { id, radInventoryId, radInventoryItemId, modalRef, listRef, resetInventoryItem } = props;
  const [fetching, setFetching] = useState(true);
  const [radInventoryItemSplit, setRadInventoryItemSplit] = useState({});

  useEffect(() => {
    (async () => {
      const apiBase = API.radiationSafety.radInventoryItemSplits(
        radInventoryId,
        radInventoryItemId,
      );
      const api = isDefined(id) ? apiBase.show : apiBase.new;
      const { ok, data } = await api(id);
      if (ok) {
        await setRadInventoryItemSplit(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return (
    <RadInventoryItemSplitForm
      radInventoryId={radInventoryId}
      radInventoryItemId={radInventoryItemId}
      radInventoryItemSplit={radInventoryItemSplit}
      modalRef={modalRef}
      listRef={listRef}
      resetInventoryItem={resetInventoryItem}
    />
  );
};
