import React, { useRef } from 'react';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSection } from '../../../components/form/FormSection';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
  valueWithUnit,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { API } from '../../../services/api';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { Modal } from '../../../components/modals/Modal';
import { dateFormatter } from '../../../../community_portal/assets/helpers/DateFormatHelper';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { RadInventoryItemSplit } from '../RadInventoryItemSplit';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.${key}`);
const listT = key => t(`lists.splits.${key}`);
const splitT = key => I18n.t(`activerecord.attributes.rad_inventory_item_split.${key}`);

export const RadInventoryItemSplitsList = props => {
  const {
    setFieldValue,
    handleSubmit,
    isSubmitting,
    resetInventoryItem,
    values: { id, radInventoryId, disposed, quantity_unit_code },
  } = props;
  const listRef = useRef(null);
  const modalRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{listT('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            hidden={!id || disposed}
            onClick={async () => {
              const afterSave = () => modalRef.current.open();
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {listT('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.radiationSafety.radInventoryItemSplits(radInventoryId, id).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.rad_inventory_item_split')}
            mapRowHrefs={({ attributes }) =>
              attributes.result_item_id
                ? `/radiation_safety/rad_inventories/${attributes.result_item_inventory_id}/rad_inventory_items/${attributes.result_item_id}/edit`
                : null
            }
            mapData={({ attributes }) => [
              [
                splitT('item_identifier'),
                attributes.result_item_identifier ?? splitT('non_exisitng_item_identifier'),
              ],
              [splitT('splitted_date_time'), dateFormatter.dateTime(attributes.splitted_date_time)],
              [
                splitT('activity'),
                `${valueWithUnit(attributes.activity, attributes.activity_unit_code)}`,
              ],
              [splitT('quantity'), `${valueWithUnit(attributes.quantity, quantity_unit_code)}`],
              <SimpleListDeletionLink
                key="delete"
                disabled={isSubmitting}
                hidden={!id || disposed}
                modelName={listT('model_name')}
                onSubmit={async () => {
                  const apiBase = API.radiationSafety.radInventoryItemSplits(radInventoryId, id);
                  const { ok, data } = await apiBase.destroy(attributes.id);
                  if (ok) {
                    await resetInventoryItem();
                    listRef.current.refresh();
                    showSuccessMessage(listT('flash.success.delete'));
                  } else {
                    showBackendErrorMessage(listT('flash.error.delete'), data);
                  }
                }}
                additionalModalContent={
                  attributes.result_item_identifier
                    ? listT('existing_child_modal_content')
                    : listT('non_existing_child_modal_content')
                }
              >
                {listT('delete')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal ref={modalRef} title={t('modals.split.title')}>
        <RadInventoryItemSplit
          modalRef={modalRef}
          listRef={listRef}
          radInventoryId={radInventoryId}
          radInventoryItemId={id}
          {...props}
        />
      </Modal>
    </>
  );
};
