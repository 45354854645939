import React, { useMemo, useRef } from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { defaultHandleSubmit, isDefined } from '../../../services/utils';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { FilesField } from '../../../components/fields/FilesField';
import { ReadOnlyGhsCodesProperties } from '../../../components/other/chemicalSafety/GhsCodesProperties/ReadOnlyGhsCodesProperties';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { Footer } from './Footer';
import { ChemicalsTable } from './ChemicalsTable';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { CompoundsList } from '../../../components/other/chemicalSafety/Compounds/Compounds';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';
import {
  setGhsValuesFromFirstCompound,
  setNfpaValuesFromFirstCompound,
} from './setFormValuesHelper';
import { EditableGhsCodesProperties } from '../../../components/other/chemicalSafety/GhsCodesProperties/EditableGhsCodesProperties';
import { NfpaProperties } from '../../../components/other/chemicalSafety/NfpaProperties/NfpaProperties';
import { AddManufacturerModalContent } from '../../../components/other/chemicalSafety/Manufacturers/AddManufacturerModalContent';

const t = key => I18n.t(`chemical_safety.chemical_libraries.chemical_definitions.new_edit.${key}`);
const createAwaitingItemInPchlModalT = key =>
  I18n.t(
    `chemical_safety.chemical_libraries.chemical_definitions.new_edit.modals.create_awaiting_item_in_pchl.${key}`,
  );

export const MainForm = props => {
  const {
    connectedToPchl,
    setShowMainForm,
    setShowPchlProductBrowser,
    setFieldValue,
    handleSubmit,
    enums: { reportingRequirementOptions, scheduleOptions },
    values: {
      id,
      pchl_product_id,
      ghs_code_ids,
      ghsCodesProperties,
      compoundIds,
      nfpa_health_hazard,
      nfpa_flammability_hazard,
      nfpa_instability_hazard,
      nfpa_special_hazard,
      chemical_manufacturer_ids,
    },
  } = props;

  const createAwaitingItemInPchlModalRef = useRef(null);
  const addManufacturerModalRef = useRef(null);

  const showDataDirectlyFromPchl = useMemo(() => connectedToPchl && !isDefined(id), [
    connectedToPchl,
    id,
  ]);

  const compoundsListApiProps = useMemo(() => {
    return showDataDirectlyFromPchl
      ? {
          compoundsApi: API.publicChemicalLibrary.compoundsProducts,
          compoundsApiParams: { product_id: pchl_product_id },
          extractCompoundFromFetchedData: fetchedData => fetchedData.compound,
        }
      : {
          compoundsApi: API.chemicalSafety.chemicalDefinitionsCompounds,
          compoundsApiParams: { chemical_definition_id: id },
          addableCompoundsApi: API.selectors.addableChemicalCompounds,
          addableCompoundsApiIndexParams: { chemical_definition_id: id },
          createProductsCompoundApi: API.chemicalSafety.chemicalDefinitionsCompounds.create,
          getCreateProductsCompoundApiParams: selectedCompoundId => ({
            chemical_definition_id: id,
            chemical_compound_id: selectedCompoundId,
          }),
          extractCompoundFromFetchedData: fetchedData => fetchedData.attributes.compound,
        };
  }, [showDataDirectlyFromPchl, pchl_product_id, id]);

  return (
    <>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.chemical_definition',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.chemical_definition'),
          disabled: connectedToPchl,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField
            name="record_type"
            disabled
            component={TextField}
            additionalContent={
              <>
                <DefaultLink
                  hidden={!isDefined(id) || connectedToPchl}
                  onClick={() => {
                    createAwaitingItemInPchlModalRef.current.open();
                  }}
                >
                  {t('links.create_awaiting_item_in_pchl')}
                </DefaultLink>
                <DefaultLink
                  hidden={connectedToPchl || isDefined(id)}
                  onClick={() => {
                    setShowMainForm(false);
                    setShowPchlProductBrowser(true);
                  }}
                >
                  {t('links.search_pchl_products')}
                </DefaultLink>
              </>
            }
          />
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="name" disabled={connectedToPchl} required component={TextField} />
          {showDataDirectlyFromPchl ? (
            <FastField
              name="pchlManufacturers"
              label={I18n.t(
                'activerecord.attributes.chemical_definition.chemical_manufacturer_ids',
              )}
              component={TextField}
            />
          ) : (
            <FastField
              name="chemical_manufacturer_ids"
              api={API.selectors.chemicalManufacturers.index}
              selectionApi={API.selectors.chemicalManufacturers.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncMultiSelectorField}
              additionalContent={
                <DefaultLink
                  hidden={connectedToPchl}
                  onClick={() => {
                    addManufacturerModalRef.current.open();
                  }}
                >
                  {t('links.add_manufacturer')}
                </DefaultLink>
              }
            />
          )}
          {showDataDirectlyFromPchl ? (
            <FastField
              name="pchlStorageGroup"
              label={I18n.t(
                'activerecord.attributes.chemical_definition.chemical_storage_group_id',
              )}
              component={TextField}
            />
          ) : (
            <FastField
              name="chemical_storage_group_id"
              api={API.selectors.chemicalStorageGroups.index}
              selectionApi={API.selectors.chemicalStorageGroups.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncSelectorField}
              isClearable
            />
          )}
          {showDataDirectlyFromPchl ? (
            <FastField
              name="pchlHhopTypes"
              label={I18n.t('activerecord.attributes.chemical_definition.hhop_type_ids')}
              component={TextField}
            />
          ) : (
            <FastField
              name="hhop_type_ids"
              api={API.selectors.hhopTypes.index}
              selectionApi={API.selectors.hhopTypes.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncMultiSelectorField}
            />
          )}
          {!connectedToPchl && <FastField name="sds_attachments" component={FilesField} />}
        </FormSection>
        <CompoundsList
          {...props}
          setProductGhsValuesFromFirstCompound={setGhsValuesFromFirstCompound(
            setFieldValue,
            compoundIds,
            {
              ghs_code_ids,
              ghsCodesProperties,
            },
          )}
          setProductNfpaValuesFromFirstCompound={setNfpaValuesFromFirstCompound(
            setFieldValue,
            compoundIds,
            {
              nfpa_health_hazard,
              nfpa_flammability_hazard,
              nfpa_instability_hazard,
              nfpa_special_hazard,
            },
          )}
          readOnly={connectedToPchl}
          translationNamespace="chemical_safety.chemical_libraries.chemical_definitions"
          compoundI18nPrefix="chemical_compound"
          {...compoundsListApiProps}
        />
        <FormSection title={t('sections.ghs_classification')}>
          {showDataDirectlyFromPchl ? (
            <FastField
              name="pchlGhsCodes"
              label={I18n.t('activerecord.attributes.chemical_definition.ghs_code_ids')}
              component={TextField}
            />
          ) : (
            <FastField
              name="ghs_code_ids"
              api={API.selectors.ghsCodes.index}
              selectionApi={API.selectors.ghsCodes.show}
              optionRenderer={htmlOptionRenderer('text')}
              component={AsyncMultiSelectorField}
            />
          )}
          {connectedToPchl ? (
            <ReadOnlyGhsCodesProperties
              ghsCodesProperties={ghsCodesProperties}
              compoundI18nPrefix="chemical_compound"
            />
          ) : (
            <EditableGhsCodesProperties />
          )}
        </FormSection>
        <FormSection title={t('sections.nfpa_classification')}>
          <NfpaProperties {...props} disabled={connectedToPchl} />
        </FormSection>
        <FormSection title={t('sections.details')}>
          <FastField name="is_controlled_substance" component={CheckBoxField} />
          <FastField
            name="reporting_requirement"
            options={mapToSelectorOptions(reportingRequirementOptions, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="is_narcotic" component={CheckBoxField} />
          <FastField
            name="schedule"
            options={mapToSelectorOptions(scheduleOptions, 'header', 'value')}
            component={SelectorField}
          />
        </FormSection>
        <Footer {...props} />
        <ChemicalsTable {...props} />
      </DefaultAttributesGroup>
      <Modal title={createAwaitingItemInPchlModalT('title')} ref={createAwaitingItemInPchlModalRef}>
        <ConfirmationModalContent
          modalRef={createAwaitingItemInPchlModalRef}
          hint={createAwaitingItemInPchlModalT('hint')}
          confirm={createAwaitingItemInPchlModalT('confirm')}
          onConfirm={async () => {
            createAwaitingItemInPchlModalRef.current.hide();
            await setFieldValue('submitType', 'save_and_create_awaiting_item_in_pchl');
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
      <Modal title={t('modals.add_manufacturer.title')} ref={addManufacturerModalRef}>
        {modalProps => (
          <AddManufacturerModalContent
            setNewManufacturerId={manufacturerId => {
              setFieldValue('chemical_manufacturer_ids', [
                ...chemical_manufacturer_ids,
                manufacturerId,
              ]);
            }}
            translationPath="chemical_safety.chemical_libraries.chemical_definitions.new_edit.modals.add_manufacturer"
            manufacturersApi={API.selectors.chemicalManufacturers}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
