import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  position: yup
    .number()
    .transformNaNValue()
    .test('position', I18n.t('validations.out_of_range'), function _(position) {
      if (this.parent.hasPosition) {
        return (
          Number.isInteger(position) && position >= 1 && position <= this.parent.maxPositionValue
        );
      }
      return true;
    }),
});
