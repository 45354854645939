import React from 'react';
import { FastField, withFormik } from 'formik';
import {
  defaultHandleSubmit,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';
import { ModalSection } from '../../../modals/ModalSection';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { TextField } from '../../../fields/TextField';
import { FormFooter, FormFooterRightPart } from '../../../form/FormFooter';
import { CancellationLink } from '../../../links/CancellationLink';
import { BrandButton } from '../../../buttons/BrandButton';

const t = (key, translationPath) => I18n.t(`${translationPath}.${key}`);

const ManufacturersModalForm = props => {
  const { isSubmitting, hide, setFieldValue, handleSubmit, translationPath } = props;

  return (
    <form>
      <ModalSection>
        <FastField
          name="name"
          label={t('name', translationPath)}
          component={TextField}
          required
          displayMode={fieldDisplayModes.WIDE_FORM_ROW}
          resourceName={I18n.t('resource_names.chemical_manufacturer')}
        />
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('save', translationPath)}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};

const ManufacturerFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const manufacturer = ManufacturerFunctions.prepareValuesToSubmit({ ...values });
    ManufacturerFunctions.create(manufacturer, formikProps);
  },
  create: async (manufacturer, formikProps) => {
    const { manufacturersApi, translationPath, setNewManufacturerId, hide } = formikProps.props;
    const { ok, data } = await manufacturersApi.create(manufacturer);
    if (ok) {
      const { id } = data;
      hide();
      setNewManufacturerId(id);
      showSuccessMessage(t('flash.success.save', translationPath));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save', translationPath), data);
    }
  },
  auxiliaryValues: {},
  backendValuesWhitelist: ['name'],
});

export const AddManufacturerModalContent = withFormik({
  mapPropsToValues: () => ManufacturerFunctions.prepareInitialValues({}),
  handleSubmit: ManufacturerFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(ManufacturersModalForm);
