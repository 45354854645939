import React from 'react';
import { FastField } from 'formik';
import { NfpaDiamond } from './NfpaDiamond';
import { mapToSelectorOptions } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../fields/SelectorField';
import { useNfpaDiamondAttributes } from './useNfpaAttributes';

export const NfpaProperties = props => {
  const {
    values: { nfpaDiamondLabels, nfpaDiamondCode },
    enums: {
      nfpaHealthHazardOptions,
      nfpaFlammabilityHazardOptions,
      nfpaInstabilityHazardOptions,
      nfpaSpecialHazardOptions,
    },
    disabled,
  } = props;

  useNfpaDiamondAttributes(props);

  return (
    <>
      <NfpaDiamond
        healthHazard={nfpaDiamondLabels?.nfpa_health_hazard}
        flammabilityHazard={nfpaDiamondLabels?.nfpa_flammability_hazard}
        instabilityHazard={nfpaDiamondLabels?.nfpa_instability_hazard}
        specialHazard={nfpaDiamondLabels?.nfpa_special_hazard}
        nfpaDiamondCode={nfpaDiamondCode}
      />
      <FastField
        name="nfpa_health_hazard"
        options={mapToSelectorOptions(nfpaHealthHazardOptions, 'header', 'value')}
        component={SelectorField}
        isClearable
        disabled={disabled}
      />
      <FastField
        name="nfpa_flammability_hazard"
        options={mapToSelectorOptions(nfpaFlammabilityHazardOptions, 'header', 'value')}
        component={SelectorField}
        isClearable
        disabled={disabled}
      />
      <FastField
        name="nfpa_instability_hazard"
        options={mapToSelectorOptions(nfpaInstabilityHazardOptions, 'header', 'value')}
        component={SelectorField}
        isClearable
        disabled={disabled}
      />
      <FastField
        name="nfpa_special_hazard"
        options={mapToSelectorOptions(nfpaSpecialHazardOptions, 'header', 'value')}
        component={SelectorField}
        isClearable
        disabled={disabled}
      />
    </>
  );
};
