import React from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../../../components/form/DefaultAttributesGroup';
import { ModalSection } from '../../../../../components/modals/ModalSection';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { FormFooter, FormFooterRightPart } from '../../../../../components/form/FormFooter';
import { defaultHandleSubmit } from '../../../../../services/utils';
import { CancellationLink } from '../../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../../components/buttons/BrandButton';
import { TextField } from '../../../../../components/fields/TextField';
import { SelectorField } from '../../../../../components/fields/SelectorField';
import { NumericField } from '../../../../../components/fields/NumericField';

const t = key => I18n.t(`administration.ums.new_edit.${key}`);

export const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    modalRef,
    enums: { um_type },
  } = props;

  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            labelI18nKeyPrefix: 'activerecord.attributes.um',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.um'),
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          }}
        >
          <FastField name="description" required component={TextField} />
          <FastField name="um_code" required component={TextField} />
          <FastField
            name="um_type"
            disabled
            component={SelectorField}
            options={mapToSelectorOptions(um_type, 'header', 'value')}
          />
          <FastField name="parent_conversion_value" required component={NumericField} />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={() => modalRef.current.hide()} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('footer.save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
